<template>
  <div class="main">
    <div class="back" @click="$router.back(0)">
      <div class="left">
        <span class="el-icon-arrow-left l"></span>
        <span>返回</span>
      </div>
    </div>
    <!-- <div class="title">对战规则</div> -->
    <div class="title">{{name}}</div>
    <div class="content">
      <!-- <p>
        1、创建活动时，可以选择拼箱人数（2/4人），一个场次最多可以选择 6
        个盲盒。
      </p>
      <p>
        3、活动参与费用即本场活动开启的盲盒价格总和，支付费用后，即视为加入房间，中途退出房间，并不会退还盲盒或参与费。
      </p> -->
      <div class="" v-html="content" style="color:white">

      </div>
    </div>
    <div class="bottom">
        <MyHomebot></MyHomebot>
    </div>
  </div>
</template>

<script>
import MyHomebot from "./my_homebot.vue";
export default {
  components: {
    MyHomebot,
  },
  data() {
    return {
      list:[],
      content:'',
      name:'',
    };
  },
  mounted() {
    // console.log(this.$route.params, "-==-=-=-=-=-=");
    // 获取规则数据
    this.$axios({
      url:'/api/index/ruletextlist',
      method:'post'
    }).then((res) => {
      if(res.data.code == 1) {
        let data = res.data.data
        for(let i=0;i<data.length;i++) {
          if(this.$route.params.title.includes(data[i].name)) {
            this.content = data[i].center
            this.name = data[i].name
            // console.log(this.content,'-------------')
            return
          }
        }
      }
    })
  },
};
</script>

<style lang="less" scoped>
.main {
  position: relative;
  width: 100vw;
  min-height: calc(100vh - 145px);
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  .title {
    font-size: 15px;
    margin-top: 7px;
  }
  .content {
    margin-top: 40px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    color: rgba(250, 250, 255, 0.8);
    font-size: 14px;
    div{
      color: white !important;
    }
    p {
      margin-bottom: 8px;
    }
  }
  .bottom{
    width: 100%;
    // position: absolute;
    // bottom: 0;
  }
}
.back {
  // width: 100px;
  border-radius: 3px;
  height: 60px;
  line-height: 60px;
  // background-color: rgba(0, 0, 0, 0.28);
  // margin-top: 30px;
  // padding: 0 30px;
  box-sizing: border-box;
  position: absolute;
  left: 10px;
  color: #a9abbc;
  font-size: 13px;
  @media (max-width: 550px) {
    //   margin-top: 15px;
    height: 35px;
    line-height: 35px;
  }
  .left {
    cursor: pointer;
    // background-color: red;
    //   width: 75px;
    &:hover {
      color: white;
      .l {
        border: 1.5px solid white !important;
      }
    }
  }
  .l {
    padding: 5px;
    border: 1.5px solid #383f4f;
    border-radius: 6px;
    margin-right: 7px;
  }
  .right {
    position: absolute;
    right: 30px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: white;
    }
    img {
      width: 20px;
      height: auto;
      margin-right: 10px;
    }
  }
}
</style>